import React, { useState } from "react";
import { Button } from "@chemaxon/design-system";
import { useNavigate } from "react-router-dom";

import ViewContainer from "src/ui/components/view-container/ViewContainer";
import MemberList from "src/ui/components/team-members/MemberList.tsx";
import TeamService, { useGetTeams } from "src/ui/services/TeamService";
import ContainerWithLoaderAndError from "src/ui/components/container-with-loader-and-error/ContainerWithLoaderAndError";
import Subheader from "src/ui/components/subheader/Subheader";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import {
  useGetTeamMembers,
  useRemoveTeamMember,
} from "src/ui/services/TeamMemberService";
import { adaptUseQueryResponseForContainerWithLoader } from "src/ui/utils/queryHelpers";
import { AppRoutePath } from "src/ui/utils/routes";
import { useGetCurrentTeam } from "src/ui/services/UserInfoService";
import { TeamMember } from "src/ui/models/TeamMember.ts";
import ConfirmDialog from "src/ui/components/confirmation/ConfirmDialog.jsx";
import RemoveMemberButton from "src/ui/components/removeMemberButton/RemoveMemberButton.tsx";
import { usePermissions } from "src/ui/utils/usePermissions.tsx";
import InformationDialog from "src/ui/components/information/InformationDialog.jsx";

const ListTeamMembersView = () => {
  const navigate = useNavigate();
  const getCurrentTeamQueryResult = useGetCurrentTeam();
  const getTeamsQueryResult = useGetTeams();
  const getTeamMembersQueryResult = useGetTeamMembers();
  const mutateRemoveTeamMember = useRemoveTeamMember();
  const { checkPermission } = usePermissions();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [teamMemberToRemove, setTeamMemberToRemove] =
    useState<TeamMember | null>(null);

  const principalCurrenTeam = getCurrentTeamQueryResult.data;
  const teams = getTeamsQueryResult.data;

  if (teams === undefined || principalCurrenTeam === undefined) {
    return null;
  }

  const teamMembers = adaptUseQueryResponseForContainerWithLoader(
    getTeamMembersQueryResult,
  );

  const currentTeam = TeamService.lookupTeamById(teams, principalCurrenTeam.id);

  if (currentTeam === undefined) {
    return null;
  }

  const addTeamMembersButton =
    currentTeam.users.length < currentTeam.teamSize ? (
      <Button
        colorVariant="primary"
        id="addTeamMemberButton"
        onClick={() => {
          navigate(AppRoutePath.TeamMemberAdd);
        }}
      >
        Add team members
      </Button>
    ) : (
      ""
    );

  const confirmDialog = (
    <ConfirmDialog
      isShown={teamMemberToRemove !== null}
      title={
        "Are you sure, you want to remove " +
        (teamMemberToRemove ? teamMemberToRemove.userName : null) +
        "?"
      }
      submit={() =>
        teamMemberToRemove &&
        mutateRemoveTeamMember
          .mutateAsync(teamMemberToRemove.id)
          .then(() => hideConfirmDialog())
          .catch(e => {
            setTeamMemberToRemove(null);
            setErrorMessage(e.message || "Unable to remove team member");
          })
      }
      cancel={() => hideConfirmDialog()}
      destructive={true}
    />
  );

  const hideConfirmDialog = () => setTeamMemberToRemove(null);

  const showConfirmDialog = (teamMember: TeamMember) =>
    setTeamMemberToRemove(teamMember);

  const closeErrorDialog = () => setErrorMessage(null);

  const renderRemoveButton = (teamMember: TeamMember) => (
    <RemoveMemberButton
      hasPermission={checkPermission(
        PlatformDomainObjectType.USER,
        PlatformPermission.DELETE,
      )}
      onClick={() => showConfirmDialog(teamMember)}
    />
  );

  return (
    <React.Fragment>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.USER,
          platformPermission: PlatformPermission.WRITE,
        }}
      >
        <Subheader
          title={`Members of ${currentTeam.teamName} team - ${currentTeam.users.length}/${currentTeam.teamSize}`}
          button={addTeamMembersButton}
        />
        <ViewContainer id={"listTeamMembersContainer"}>
          <ContainerWithLoaderAndError
            data={teamMembers}
            content={teamMembers => {
              return (
                <>
                  <MemberList
                    memberList={teamMembers}
                    renderRemoveButton={renderRemoveButton}
                  />
                  {confirmDialog}

                  <InformationDialog
                    isShown={errorMessage !== null}
                    close={() => closeErrorDialog()}
                  >
                    <div>{errorMessage}</div>
                  </InformationDialog>
                </>
              );
            }}
          />
        </ViewContainer>
      </Restricted>
    </React.Fragment>
  );
};

export default ListTeamMembersView;
