import React, { ChangeEvent } from "react";
import log from "loglevel";
import { flow } from "lodash";
import ViewContainer from "src/ui/components/view-container/ViewContainer";
import { Button, FormControlLabel, Switch } from "@chemaxon/design-system";
import { TextField } from "@chemaxon/design-system";
import Subheader from "src/ui/components/subheader/Subheader";
import { HelperTextTypography } from "src/ui/typography/HelperTextTypography";
import withRouter from "src/ui/utils/WithRouter";
import FormLayoutVertical from "src/ui/components/form/FormLayoutVertical";
import FormActions from "src/ui/components/form/FormActions";
import { Restricted } from "src/ui/utils/Restricted";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import Chip from "@mui/material/Chip";
import { Typography } from "@chemaxon/design-system/typography";
import { Stack } from "@mui/material";
import { FieldError } from "src/ui/services/SynergySpringMVCError";
import { NavigateFunction, Params } from "react-router-dom";
import { Info, Brand2 } from "@chemaxon/design-system/color";
import { Team } from "src/ui/models/Team";
import { withTeamMemberService } from "src/ui/utils/withTeamMemberService";
import { useAddTeamMembers } from "src/ui/services/TeamMemberService";
import { withUserInfo } from "src/ui/utils/withUserInfo";
import { withSnackbar } from "src/ui/utils/withSnackbar";
import { closeSnackbar, EnqueueSnackbar, SnackbarKey } from "notistack";
import { onCloseAction } from "src/ui/components/alerts/SnackbarCloseButton";

const HELPER_TEXTS: FormErrorType = {
  teamMembers:
    "Please enter the email addresses of the team members you would like to add, separated by semicolons, commas or new lines",
  isTeamAdmin: "",
  isProjectCreator: "",
};

class AddTeamMembersView extends React.Component<Props, StateType> {
  props: Props;
  state: StateType;

  constructor(props: Props) {
    super(props);

    this.props = props;

    this.state = {
      formData: {
        teamMembers: "",
        isTeamAdmin: false,
        isProjectCreator: false,
      },
      formErrors: {
        teamMembers: "",
        isTeamAdmin: "",
        isProjectCreator: "",
      },
    };

    //type FormState = typeof this.state;
  }

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const teamId = this.props.currentTeam.id;

    const userNameList = this.state.formData.teamMembers
      .split(/\s*[,;\t\n\s]\s*/)
      .filter(u => u !== "");

    const groups = [];
    if (this.state.formData.isTeamAdmin) {
      groups.push("TEAM_ADMIN");
    }
    if (this.state.formData.isProjectCreator) {
      groups.push("PROJECT_CREATOR");
    }

    //TODO: add a server-side validation error for empty 'users' and then remove this
    if (userNameList.length === 0) {
      this.props.snackbar.enqueueSnackbar(`No user specified.`, {
        variant: "warning",
        persist: true,
        action: onCloseAction(closeSnackbar),
      });
      return;
    }

    this.props.teamMemberService
      .addTeamMembers({ userNameList, teamId, groups })
      .then(() => {
        this.props.router.navigate("/team/members");
      })
      .catch(err => {
        //custom error handling for multiple users
        //TODO: if this method is the finally accepted, move it into a service and add unit tests
        if (err.getFieldErrors) {
          //collecting wrong usernames
          const wrongUsernamesByErrorMessage: { [key: string]: string[] } = {};
          err.getFieldErrors().forEach((fieldError: FieldError) => {
            if (/users\[\d+\]\.userName/.test(fieldError.field)) {
              const errorMessage = fieldError.message;
              if (wrongUsernamesByErrorMessage[errorMessage] === undefined) {
                wrongUsernamesByErrorMessage[errorMessage] = [];
              }

              const indexMatcher = fieldError.field.match(/\d+/);

              if (indexMatcher != null) {
                const index = parseInt(indexMatcher[0]);
                wrongUsernamesByErrorMessage[errorMessage][index] =
                  userNameList[index]; //storing by index to keep original order
              }
            }
          });

          //composing error message
          const errorMessageTexts = [];
          err.getFieldErrors().forEach((fieldError: FieldError) => {
            if (fieldError.field === "users") {
              errorMessageTexts.push(fieldError.message);
            }
          });

          for (const errorMessage in wrongUsernamesByErrorMessage) {
            if (
              Object.prototype.hasOwnProperty.call(
                wrongUsernamesByErrorMessage,
                errorMessage,
              )
            ) {
              const userNames = wrongUsernamesByErrorMessage[errorMessage]
                .filter(u => u !== undefined)
                .join(", ");
              errorMessageTexts.push(`${errorMessage} (${userNames})`);
            }
          }

          //showing a custom error
          if (errorMessageTexts.length) {
            this.props.snackbar.enqueueSnackbar(
              `Adding team members failed: ${errorMessageTexts.join(", ")}`,
              {
                variant: "error",
                persist: true,
                action: onCloseAction(closeSnackbar),
              },
            );
            log.error("Adding team members failed");
            return;
          }
        }

        if (typeof err.message === "string") {
          this.props.snackbar.enqueueSnackbar(`Adding team members failed.`, {
            variant: "error",
            persist: true,
            action: onCloseAction(closeSnackbar),
          });
        }
        log.error("Adding team members failed");
      });
  };

  handleChange = (fieldName: keyof FormDataType) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [fieldName]: event.target.value,
        },
        formErrors: {
          ...prevState.formErrors,
          [fieldName]: "", // clearing potentially shown error message under the field
        },
      }));
    };
  };

  handleCancelButton = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.router.navigate(-1);
  };

  isInErrorState(field: keyof FormErrorType) {
    return !!(this.state.formErrors[field] || "");
  }

  getShownText(field: keyof FormErrorType) {
    return this.isInErrorState(field)
      ? this.state.formErrors[field]
      : HELPER_TEXTS[field];
  }

  getGeneralInputProps(field: keyof FormDataType) {
    return {
      name: field,
      value: this.state.formData[field],
      autoComplete: "off",
      fullWidth: true,
    };
  }

  getGeneralProps(field: keyof FormDataType) {
    return {
      className: this.isInErrorState(field) ? "error" : "",
      helperText: (
        <HelperTextTypography
          text={this.getShownText(field)}
          isError={this.isInErrorState(field)}
        />
      ),
      onChange: this.handleChange(field),
      error: this.isInErrorState(field),
    };
  }

  render() {
    return (
      <React.Fragment>
        <Restricted
          to={{
            platformDomainObjectType: PlatformDomainObjectType.USER,
            platformPermission: PlatformPermission.CREATE,
          }}
        >
          <Subheader title={"Add Team Members"} />
          <ViewContainer id={"addTeamMembersContainer"}>
            <form id="addTeamMembers" onSubmit={this.handleSubmit}>
              <FormLayoutVertical>
                <Typography variant="label2">Team members</Typography>
                <TextField
                  {...this.getGeneralProps("teamMembers")}
                  InputProps={{
                    ...this.getGeneralInputProps("teamMembers"),
                    required: true,
                    autoFocus: true,
                    id: "teamMembers",
                  }}
                  multiline
                />

                <Typography variant="label2"> Permissions </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.formData.isProjectCreator}
                      onChange={this.handleSwitchChange("isProjectCreator")}
                      size="medium"
                    />
                  }
                  label={
                    <Stack
                      direction="row"
                      spacing={4}
                      alignItems="center"
                      component="span"
                    >
                      <Chip
                        style={{
                          backgroundColor: Info.container,
                          color: Info.containerText,
                        }}
                        label={
                          <Typography variant="label3">
                            Project creator
                          </Typography>
                        }
                      ></Chip>
                      <Typography variant="body2" component="span">
                        Can create projects on Chemaxon Cloud
                      </Typography>
                    </Stack>
                  }
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.formData.isTeamAdmin}
                      onChange={this.handleSwitchChange("isTeamAdmin")}
                      size="medium"
                    />
                  }
                  label={
                    <Stack
                      direction="row"
                      spacing={4}
                      alignItems="center"
                      component="span"
                    >
                      <Chip
                        style={{
                          backgroundColor: Brand2.container,
                          color: Brand2.containerText,
                        }}
                        label={
                          <Typography variant="label3">Team-admin</Typography>
                        }
                      ></Chip>
                      <Typography
                        fontStyle="italic"
                        color="grey"
                        variant="label3"
                        sx={{ marginLeft: 1 }}
                      >
                        (Formerly called SUPER_ADMIN)
                      </Typography>
                      <Typography variant="body2" component="span">
                        Can manage other members and create projects on Chemaxon
                        Cloud
                      </Typography>
                    </Stack>
                  }
                />

                <FormActions>
                  <Button colorVariant="primary" onClick={this.handleSubmit}>
                    Add team members
                  </Button>
                  <Button
                    colorVariant="secondary"
                    onClick={this.handleCancelButton}
                  >
                    Cancel
                  </Button>
                </FormActions>
              </FormLayoutVertical>
            </form>
          </ViewContainer>
        </Restricted>
      </React.Fragment>
    );
  }

  handleSwitchChange = (fieldName: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [fieldName]: (event.target as HTMLInputElement).checked,
        },
      }));
    };
  };
}

interface Props {
  currentTeam: Team;
  teamMemberService: {
    addTeamMembers: ReturnType<typeof useAddTeamMembers>["mutateAsync"];
  };
  router: {
    navigate: NavigateFunction;
    location: Location;
    params: Readonly<Params>;
  };
  snackbar: {
    enqueueSnackbar: EnqueueSnackbar;
    closeSnackbar: (key?: SnackbarKey) => void;
  };
}

const enhance = flow(
  withSnackbar,
  withUserInfo,
  withTeamMemberService,
  withRouter,
);

export default enhance(AddTeamMembersView);

type StateType = {
  formData: FormDataType;
  formErrors: FormErrorType;
  formErrorMessage?: string;
};

type FormDataType = {
  teamMembers: string;
  isTeamAdmin: boolean;
  isProjectCreator: boolean;
};

type FormErrorType = {
  [K in keyof FormDataType]: string;
};
