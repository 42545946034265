import { Warning } from "@chemaxon/design-system/color";

import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import SynergyMUIDataTableSearchBox from "src/ui/components/mui-datatables-search-box/SynergyMUIDataTableSearchBox";
import Tooltip from "@mui/material/Tooltip";

import "src/ui/components/team-members/TeamMembersList.scss";
import { TeamMember, UserStatus } from "src/ui/models/TeamMember.ts";
import { WarningOutline } from "@chemaxon/design-system/icons";
import { Typography } from "@chemaxon/design-system/typography";
import moment from "moment";

function MemberList(props: Props) {
  const getMuiTableOptions = (): MUIDataTableOptions => {
    return {
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (
          <SynergyMUIDataTableSearchBox
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
          />
        );
      },
      filterType: "dropdown",
      searchOpen: true,
      download: false,
      print: false,
      selectableRows: "none",
      elevation: 0,
      filter: false,
      viewColumns: false,
      textLabels: {
        body: {
          noMatch: "No registered team members.",
        },
      },
      sortOrder: {
        name: "Name",
        direction: "asc",
      },
    };
  };

  const getTableColumns = () => {
    const columns = [];

    columns.push({
      name: "Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 320,
            },
          };
        },
        customBodyRender: renderName,
      },
    });
    columns.push({
      name: "Last active",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderLastActiveCell,
      },
    });
    if (props.renderRemoveButton) {
      columns.push({
        name: "",
        options: {
          filter: false,
          sort: false,
          searchable: false,
          customBodyRender: props.renderRemoveButton,
        },
      });
    }

    return columns;
  };

  const renderName = (name: string) => {
    if (name.length > 40) {
      const truncName = name.substring(0, 39) + "...";
      return (
        <Tooltip title={name}>
          <span>{truncName}</span>
        </Tooltip>
      );
    }
    return <span>{name}</span>;
  };

  const getTableData = (members: TeamMember[]): DataRow[] =>
    members.map(user => [
      user.userName,
      {
        status: user.status,
        lastActive: user.lastActive,
      },
      user,
    ]);

  type DataRow = [
    userName: string,
    status: UserStatusWithDate,
    user: TeamMember,
  ];

  type UserStatusWithDate = {
    status: UserStatus;
    lastActive: Date;
  };

  const renderLastActiveCell = (userStatusWithDate: UserStatusWithDate) => {
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        {getLastActivityCell(
          userStatusWithDate.status,
          userStatusWithDate.lastActive,
        )}
      </div>
    );
  };

  const getLastActivityCell = (status: UserStatus, lastActive: Date) => {
    switch (status) {
      case UserStatus.INVITED:
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              verticalAlign: "middle",
              display: "flex",
              gap: "4px",
            }}
          >
            <WarningOutline
              sx={{ color: Warning.default }}
              fontSize={"small"}
            />
            <Typography variant="body2" strong color={Warning.containerText}>
              Pending invitation
            </Typography>
          </div>
        );
      case UserStatus.WAITING_FOR_REGISTRATION:
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              verticalAlign: "middle",
              display: "flex",
            }}
          >
            <WarningOutline
              sx={{ color: Warning.default }}
              fontSize={"small"}
            />
            <Typography variant="body2" strong color={Warning.containerText}>
              Waiting for registration
            </Typography>
          </div>
        );
      default:
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              verticalAlign: "middle",
              display: "flex",
            }}
          >
            <Typography variant="body2">
              {lastActive != null
                ? moment(lastActive, "YYYYMMDD hh:mm:ss").utc(true).fromNow()
                : ""}
            </Typography>
          </div>
        );
    }
  };

  return (
    <div className="memberList">
      <MUIDataTable
        title={""}
        columns={getTableColumns()}
        data={getTableData(props.memberList)}
        options={getMuiTableOptions()}
      />
    </div>
  );
}

type Props = {
  memberList: TeamMember[];
  renderRemoveButton?: (teamMember: TeamMember) => React.ReactNode;
};

export default MemberList;
